import React from "react";
import {Helmet} from "react-helmet";
export default ({ data }) => {
    return (

            <div className="not-found">
              <Helmet htmlAttributes={{itemtype: 'notfound'}} >
                  <style>{`
                      h1 {
                          font-family: 'Segoe UI Light';
                          font-weight: 300;
                          color: #fff;
                      }

                      p {
                         color: #fff;
                        }
                      
                      html[itemtype='notfound'] body {
                          font-family: 'Segoe UI';
                          color: #fff;
                          background: #888888;
                          margin: 0;
                      }
                      
                      .not-found main {
                          margin: 4% 10%;
                      }
                      
                      .emotion {
                          font-size: 8em;
                          margin: 0;
                      }
                  `}</style>
              </Helmet>
              <main>
                  <header>
                      <h1 className="emotion">:(</h1>
                  </header>
                  <p>The link does not exist. If you think this is a problem, please contact me though the following <a style={{backgroundColor:"white"}} href={"https://contact.biniljacob.com"}>Link</a>.</p>
                  <p>-----------</p>
              </main>
            </div>
          );










    }